// Colors

// Palette active colors
//$colors: get("colors");
$colors: (
		/* -- COLORI EDITABILI -- */
	 primary_a: #161413, //Scuro, testi
		//Se ticketag-for-teams, è necessario che i colori sottostanti siano uguali a primary_a
	 primary_j: #5E7891,
	 primary_k: #1D0F51,
	 primary_p: #70BE4B,
	 primary_q: #0091D2,

		/*----*/

	 primary_b: #1D0F51, //Colore scuro squadra
		//Se ticketag-for-teams, è necessario che i colori sottostanti siano uguali a primary_b
	 primary_c: #00A3ED,
	 primary_e: #70BE4B,
	 primary_f: #5E7891,
	 primary_h: #0091D2,
	 primary_i: #6CC56F,
	 primary_n: #82C44C,

		/*----*/

	 link_color: #ffd140, //Colore chiaro squadra
		//Se ticketag-for-teams, è necessario che i colori sottostanti siano uguali a link_color
	 primary_d: #70BE4B,
	 primary_g: #0091D2,
	 primary_l: #FFFFFF,
	 primary_m: #1D0F51,
	 primary_o: #96D8EE,
	 primary_r: rgba(0, 145, 210, .6),

		/*----*/

	 text_a: #161413, //Colore scuro testi

	 images_gradient: linear-gradient(360deg, #1D0F51 0%, rgba(255, 211, 0, 0) 50%),				//Gradiente per le immagini
	 primary_gradient: linear-gradient(to right, #FFD300 0%, #092782 100%),  						//Gradiente principale
	 footer_color: #1D0F51,  																														//Colore sfondo footer

		/*----*/

	 text_b: #808080,
		//Se ticketag-for-teams, è necessario che i colori sottostanti siano uguali a text_b
	 text_c: #D8ECEE,

		/* -- COLORI EDITABILI -- */

	 blue: #0091d2,
	 green: #70BE4B,
	 red_google: #EB5757,
	 hover_color: rgba(255,209,64,.27)
);

// Useful colors
$theme-colors: (
	 "heading-color": #1D0F51,
	 "paragraph-color": #5E7891,
	 "card-color" : #96D8EE
);

// Useful gradient

$default-gradient-start: #70BE4B;
$default-gradient-end: #00A3ED;

$default-gradient: linear-gradient(to right, $default-gradient-start 0%, $default-gradient-end 100%);
$vertical-gradient: linear-gradient(to top, $default-gradient-start 0%, $default-gradient-end 100%);
$darken-gradient: linear-gradient(to right, darken($default-gradient-start,20) 0%, darken($default-gradient-end,20) 100%);
$second-gradient: linear-gradient(to right, map_get($colors, "primary_e") 0%, map_get($colors, "primary_g") 100%);
$default-gradient-reverse: linear-gradient(to left, $default-gradient-start 0%, $default-gradient-end 100%);
$second-gradient-reverse: linear-gradient(to left, map_get($colors, "primary_e") 0%, map_get($colors, "primary_g") 100%);
$cards-default-gradient: linear-gradient(180.3deg, rgba(53, 38, 115, 0) 50%, #352673 99.56%);
$cards-blue-gradient: linear-gradient(132.34deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.2) 53.84%);

$body-color: map_get($theme-colors, "paragraph-color");
$headings-color: map_get($theme-colors, "heading-color");
$red: map_get($colors, "red-google");

$greenTicketag: rgba(130, 196, 76, .5);
$blueTicketag: rgba(23, 148, 207, .5);

/*TICKETAG FOR TEAMS EDIT*/

// Fonts
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,800');
@import url('https://fonts.googleapis.com/css?family=Exo+2:400,500,600,700,800');

$font-family-montserrat: 'Montserrat', sans-serif;
$font-family-exo2: 'Exo 2', sans-serif;
$font-family-base: $font-family-exo2;

$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
//$font-weight-bold: 700;
$font-weight-base: $font-weight-medium;
$headings-font-weight: $font-weight-medium;

$form-feedback-valid-color: $body-color;

$font-sizing-definition: (
	 h1: (
			sm: 24px,
			md: 30px,
			lg: 48px
	 ),
	 h2: (
			sm: 18px,
			md: 24px,
			lg: 36px
	 ),
	 h3: (
			sm: 16px,
			md: 18px,
			lg: 24px
	 ),
	 h4: (
			sm: 14px,
			md: 16px,
			lg: 18px
	 ),
	 h5: (
			sm: 12px,
			md: 14px,
			lg: 16px
	 ),
	 p: (
			sm: 12px,
			md: 14px,
			lg: 14px
	 ),
	 a: (
			sm: 14px,
			md: 10px,
			lg: 10px
	 )
);
$label-font-size: 12px;
$label-2-font-size: 10px;
$white: #fff;



