.invalid-feedback {
  font-weight: $font-weight-semi-bold;
}

.form-control {
  border-radius: 2px;
  border: 1px solid rgba(29, 15, 81, 0.1);

  &:focus {
    box-shadow: none;
  }
}

//.was-validated .form-control:valid {
//  border-color: rgba(29, 15, 81, 0.1);
//}

a {
  cursor: pointer;
}

.link {
  font-weight: 500;
  line-height: 24px;
  font-size: inherit;
  color: inherit;

  &--underlined {
    text-decoration-line: underline !important;
  }

  &--green {
    color: map_get($colors, "primary_e");
  }
}

.form-check {
  &-label {
    user-select: none;
    padding-left: 12px;
  }

  &-input {
    appearance: none;
    opacity: 0;

    &:disabled ~ .form-check-label {
      opacity: .5;
    }
  }

  &-select {
    appearance: none;
    border: none;
    padding: 0;
    font-size: 14px;
    opacity: .5;
    background: none;

    &:focus {
      outline: none;
      border:none;
      box-shadow: none;
      background: none;
    }
  }
}

.form-group.form-check + p, .form-group.form-check + ul{
  margin-top: 14px;
}

.form-group.form-check + ul{
  margin-bottom: 10px;
}

.form-group.form-check {
  margin-bottom: 10px !important;
}

.StripeElement {
  border-radius: 2px;
  border: 1px solid rgba(29, 15, 81, 0.1);
  display: block;
  width: 100%;
  height: 38px;
  padding: 10px;
  //background-color: #fff;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  line-height: 20px !important;
  font-size: 12px !important;
  font-weight: $font-weight-medium !important;

  &:hover {
    //border-color: rgba(29, 15, 81, .48);
  }
  &--focus {
    //border-color: rgba(29, 15, 81, 1) !important;
    box-shadow: none;
  }

  &--invalid {
    //border-color: $red !important;
  }
}

.input-invalid {
  font-weight: $font-weight-semi-bold;
  //color: $red;
}

.form-list {
  padding-left: 6px;
  list-style-type: none;

  &__number {
    padding-left: 15px;
    list-style-type: decimal;
  }

  &__element {
    position: relative;
    font-size: 12px;
    line-height: 20px;

    :not(.form-list__number)::before {
      content: "";
      width: 2px;
      height: 2px;
      background-color: map_get($colors, "primary_f");
      position: absolute;
      left: -4px;
      top: 9px;
      border-radius: 10px;
    }
  }
}

.select {
  position: relative;

  &::after {
    content: ' ';
    position: absolute;
    z-index: -1;
    background: $body-color;
    opacity: 0.5;
    width: 9px;
    height: 5px;
    top: 50%;
    left: 20px;
    transform: translate(0,-50%);
    //mask: url("img_static/arrow.svg") no-repeat center;
    mask-size: 100%;
  }
}

input, textarea{
  // font-weight: $font-weight-medium !important;

  &:hover {
    border-color: map_get($colors, "primary_b") !important;
  }

  &:focus {
    border-color: map_get($colors, "primary_b") !important;
    box-shadow: none;
  }
}

//input.es-input {
//  background: url("img_static/bottom-arrow.svg") right center no-repeat !important;
//
//  @include media-breakpoint-down(sm) {
//    background: url("img_static/bottom-arrow.svg") right 10px center no-repeat, transparentize($color: toRGB(map_get($colors, "primary_b")), $amount: 0.97) !important;
//  }
//}

ul.es-list {
  position: absolute;
  padding: 0;
  margin: 0;
  border-radius: 0 0 5px 5px;
  border: none;
  color: $headings-color;
  display: none;
  font-size: 12px;
  z-index: 600;
  background: #fff;
  max-height: 160px;
  overflow-y: auto;
  box-shadow: 0 8px 9px 0 #ccc;
}

.es-list li {
  margin: 3px !important;
  &.selected {
    border-radius: 5px;
  }
}

//For Radios
input[type=radio] + label::after {
  content: '';
  background: $white;
  background-size: cover;
  border: solid 1px #BDBDBD;
  position: absolute;
  height: 20px;
  width: 20px;
  border-radius: 20px;
  left: 0;
}

//For checked Radios
input[type=radio]:checked + label{
  font-weight: 600;
}
input[type=radio]:checked + label::after{
  border: none;
  background: $white url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='19' height='19' rx='9.5' stroke='url(%23paint0_linear)'/%3E%3Crect x='3' y='3' width='14' height='14' rx='7' fill='url(%23paint1_linear)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear' x1='20' y1='-3.22878e-06' x2='-2.34081' y2='3.09315' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0.0246272' stop-color='#{encodecolor(map_get($colors, "primary_e"))}'/%3E%3Cstop offset='1' stop-color='#{encodecolor(map_get($colors, "primary_h"))}'/%3E%3C/linearGradient%3E%3ClinearGradient id='paint1_linear' x1='17' y1='3' x2='1.36143' y2='5.16521' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0.0246272' stop-color='#{encodecolor(map_get($colors, "primary_e"))}'/%3E%3Cstop offset='1' stop-color='#{encodecolor(map_get($colors, "primary_h"))}'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
  background-size: cover;
}

//For Radios on focus
input[type=radio]:focus {
  outline: none;
  & + label::after{
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
}

//For Checkboxes
input[type=checkbox] + label::after {
  content: '';
  background: $white;
  background-size: cover;
  border: solid 1px #BDBDBD;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 20px;
  width: 20px;
  border-radius: 4px;
  left: 0;
}

input[type=checkbox].checkboxes-top + label::after {
  top: 5px;
  transform: none;

}

//For checked Checkboxes
//input[type=checkbox]:checked + label{
//font-weight: 600;
//}

//For checked Checkboxes
input[type=checkbox]:checked + label::after{
  border: none;
  background: $white url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='1' y='1' width='20' height='20' rx='3' stroke='url(%23paint0_linear)'/%3E%3Cpath d='M5.1941 11.5382C4.9353 11.7929 4.9353 12.2058 5.1941 12.4604L7.85396 15.0778L8.0897 15.3098C8.47888 15.6928 9.10331 15.6928 9.4925 15.3098L9.72826 15.0778L16.8059 8.1132C17.0647 7.85853 17.0647 7.44567 16.8059 7.191C16.5471 6.93633 16.1275 6.93633 15.8687 7.191L9.49249 13.4654C9.10331 13.8484 8.47889 13.8484 8.0897 13.4654L6.13126 11.5382C5.87246 11.2836 5.45287 11.2836 5.1941 11.5382Z' fill='url(%23paint1_linear)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear' x1='1' y1='1' x2='23.2626' y2='3.95524' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='#{encodecolor(map_get($colors, "primary_e"))}'/%3E%3Cstop offset='1' stop-color='#{encodecolor(map_get($colors, "primary_h"))}'/%3E%3C/linearGradient%3E%3ClinearGradient id='paint1_linear' x1='17' y1='7' x2='3.78878' y2='9.43885' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0.0246272' stop-color='#{encodecolor(map_get($colors, "primary_e"))}'/%3E%3Cstop offset='1' stop-color='#{encodecolor(map_get($colors, "primary_h"))}'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
  background-size: cover;
}

//For Checkboxes on focus
input[type=checkbox]:focus {
  outline: none;
  & + label::after{
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
}





// Range
.range-selector {
  display: block;
  margin: 0;
  overflow: hidden;
  width: 100%;
  height: 21px;
  padding-left: 0; // Firefox specific
  cursor: pointer;
  background-color: transparent;
  appearance: none;

  &:focus {
    outline: none;
  }

  &::-moz-focus-outer {
    border: 0;
  }

  &::-webkit-slider-thumb {
    position: relative;
    width: 21px;
    height: 21px;
    margin-top: -8px; // Webkit specific
    border: none;
    border-radius: 100px;
    background-color: map_get($colors, "primary_e");
    transition: all .1s;
    //box-shadow: webkit-slider-thumb-shadow(1, #F5F6F7);
    cursor: pointer;
    appearance: none;
  }

  &::-webkit-slider-runnable-track {
    height: 5px;
    color: transparent;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    background: $second-gradient-reverse;
  }

  &::-moz-range-thumb {
    width: 21px;
    height: 21px;
    margin-top: -8px; // Webkit specific
    border: none;
    border-radius: 100px;
    background-color: map_get($colors, "primary_e");
    transition: all .1s;
    appearance: none;
  }

  &::-moz-range-track {
    width: 100%;
    height: 5px;
    color: transparent;
    cursor: pointer;
    background: #F4F5F6;
    border-radius: 5px;
    border-color: transparent; // Firefox specific?
  }

  &::-moz-range-progress {
    height: 5px;
    cursor: pointer;
    background: $second-gradient-reverse;
    border-radius: 5px;
    border-color: transparent; // Firefox specific?
  }


  &::-ms-thumb {
    width: 21px;
    height: 21px;
    margin-top: -8px; // Webkit specific
    border: none;
    border-radius: 100px;
    background-color: map_get($colors, "primary_e");
    transition: all .1s;
    margin-right: .2rem; // Workaround that overflowed box-shadow is hidden.
    margin-left: .2rem;  // Workaround that overflowed box-shadow is hidden.
    appearance: none;
  }

  &::-ms-track {
    width: 100%;
    height: 5px;
    color: transparent;
    cursor: pointer;
    background: #F4F5F6;
    border-radius: 5px;
    border-color: transparent; // Firefox specific?
  }

  &::-ms-fill-lower {
    background: $second-gradient-reverse;
  }
  &::-ms-fill-upper {
    background-color: #F4F5F6;
  }
}


.filters__header--expanded .range-selector {
  &::-webkit-slider-thumb {
    //box-shadow: webkit-slider-thumb-shadow(1, #fff);
  }
}


@keyframes openSelect {
  from {
    max-height: 0;
  }

  to {
    max-height: 150px;
  }
}
