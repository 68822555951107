body{
  .checkbox {
    &-label {
      user-select: none;
      padding-left: 12px;
    }

    &-input {
      appearance: none;
      opacity: 0;

      &:disabled ~ .form-check-label {
        opacity: .5;
      }
    }

    &-select {
      appearance: none;
      border: none;
      padding: 0;
      font-size: 14px;
      opacity: .5;
      background: none;

      &:focus {
        outline: none;
        border: none;
        box-shadow: none;
        background: none;
      }
    }
  }
}
