/*@import "abstracts/variables";
//@import "_demo";

@import "abstracts/exports";*/


:global {
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @import "node_modules/@ticketag/ticketag-uilib/src/sass/abstracts/animations";
  @import "node_modules/@ticketag/ticketag-uilib/src/sass/main";

  .partial-loader{
    -webkit-animation-name: rotate;
    -webkit-animation-duration: 4000ms;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;

    -moz-animation-name: rotate;
    -moz-animation-duration: 4000ms;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;

    -ms-animation-name: rotate;
    -ms-animation-duration: 4000ms;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;

    animation-name: rotate;
    animation-duration: 4000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}

body{
  margin: 0;
  font-family: "Exo 2", sans-serif;
}