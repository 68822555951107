.toggle-button {
  font-weight: 500;
  user-select: none;
  display: inline-flex;
  //padding: 8px 20px;
  padding: 5px 20px;
  line-height: 24px;
  margin: 0 10px 10px 0;
  font-size: 14px;
  color: #BDBDBD;
  background: #F5F6F7;
  border-radius: 605px;
  cursor: pointer;

  &.active {
    //color: #FFFFFF;//set-button-text-color(toRGB(map_get($colors, "primary_b")));
    //background: #0000FF; //map_get($colors, "primary_b");
    position: relative;
    padding-right: 36px;

    .close-icon {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translate(0,-50%);
      height: 7px;
      width: 7px;
      content: ' ';
      //background-color: set-button-text-color(toRGB(map_get($colors, "primary_b")));
      //mask: url('img_static/close-icon.svg') no-repeat center;
      mask-size: 100%;
    }

  }

}