@keyframes rotate {
	0% {
		transform: rotate(0deg);
	}
	50% {
		transform: rotate(180deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
.loader {
		&__container {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 890;
				background-color: transparentize($white, .2);
		}

		&__sm {
			transform: scale(0.5);
		}

		position: relative;
		//left: calc(50% - (150px/2));
		//top: calc(50% - (150px/2));
		width: 150px;
		height: 150px;
		display: block;
		overflow: hidden;

		div {
				height: 100%;
		}

		&, & div {
			box-sizing: border-box;
				@include preloader-div-setup;
				border-top-color: map_get($colors, "primary_d");
				border-left-color: map_get($colors, "primary_h");
				animation-timing-function: cubic-bezier(.55, .38, .21, .88);
				animation-duration: 3s;
		}

		&, & * {
				will-change: transform;
		}
}
