body {
	.btn {
		//border-radius: 100px;
		font-size: 12px;
		border: none;
		line-height: 23px;

		//For Devices larger than 992px
		/*@include media-breakpoint-up(lg){
				padding: 13px;
				line-height: 25px;
		}*/

		&:focus{
			box-shadow: none;
		}

		&--icon {
			align-items: center;
			justify-content: center;
			display: flex;
		}

		&--facebook {
			align-items: center;
			justify-content: center;
			display: flex;
			background-color: #4267B2;
			color: $white;
			font-weight: $font-weight-medium;

			//@include button-hover;
		}

		&--google {
			align-items: center;
			justify-content: center;
			display: flex;
			background-color: $white;
			//background-color: $red;
			//color: $white;
			color: $red;
			font-weight: $font-weight-medium;
			box-shadow: 0 0 50px rgba(14, 21, 85, 0.2);

			//&:before {
			//		content: '';
			//		width: 100%;
			//		height: 100%;
			//		background: #0E1555;
			//		opacity: 0.7;
			//		filter: blur(50px);
			//}

			//@include button-hover;
		}

		&--red {
			font-weight: $font-weight-semi-bold;
			font-size: 14px;
			letter-spacing: 0.05em;
			text-transform: uppercase;
		}

		&--gradient {
			background-clip: padding-box;
			font-weight: $font-weight-semi-bold;
			font-size: 14px;
			letter-spacing: 0.05em;
			text-transform: uppercase;
			box-shadow: 0 9px 28px rgba(toRGB(map_get($colors, "primary_i")), .2); //TODO: da rìguardare

			//@include button-hover;

			&:hover {
			}
		}

		&--no-shadow {
			box-shadow: none !important;
		}

		&--outlined {
			//border-radius: 100px;
			border: transparent;

			&--red {
				box-shadow: none !important;
			}

			&::after{
				content: '';
				width: calc(100% - 2px);
				height: calc(100% - 2px);
				//border-radius: 100px;
				position: absolute;
				top: 1px;
				left: 1px;
				transition: .3s all;
			}

			&:hover {
				box-shadow: 0 9px 28px rgba(toRGB(map_get($colors, "primary_i")), .2);

				&::after {
					opacity: 0;
				}

				.btn__text-gradient {
					-webkit-background-clip: text;
					transition: .3s all;
				}

				.btn__text-gradient-red {

					transition: .3s all;
				}
			}
		}

		&__text-gradient {
			position: relative;
			z-index: 2;
			font-weight: $font-weight-semi-bold;
			font-size: 13px;
			letter-spacing: 0.05em;
			text-transform: uppercase;
			-webkit-text-fill-color: transparent;
			-webkit-background-clip: text;
		}

		&__text-gradient-red {
			position: relative;
			z-index: 2;
			font-weight: $font-weight-semi-bold;
			font-size: 12px;
			letter-spacing: 0.05em;
			text-transform: uppercase;
		}
	}
}
