.profileHeader {

    &__top {
        /*
        overflow: hidden;
        display: flex;
        flex-direction: column;
        height: 131px;
        padding-top: 15px;
        padding-left: 15px;
        background: linear-gradient(249.97deg, transparentize(toRGB(map_get($colors, "primary_e")), 0.3) 2.46%, transparentize(toRGB(map_get($colors, "primary_g")), 0.1) 100%);
        border-radius: 5px 5px 0px 0px;
        */
        &__info {
            display: flex;
            flex-direction: row;

            &__image-wrapper {
                position: relative;
/*
                @include media-breakpoint-up(md) {
                    position: absolute;
                    height: 108px;
                    width: 108px;
                    left: 0;
                    bottom: 0;
                    transform: translate(45px, -20px);
                }
*/
 //               @include media-breakpoint-up(lg) {
                    height: 162px;
                    width: 162px;
 //                 transform: translate(63px, -23px);
//                }

                &::before {
                    content: ' ';
                    opacity: 0;
                    transition: opacity 0.5s ease-in-out;
                }

                &:hover,
                &.hover,
                &.active {
                    &::before {
//                        border: 2px solid #FFFFFF;
                        border-radius: 100px;
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        background: linear-gradient(0deg, rgba(29, 15, 81, 0.1), rgba(29, 15, 81, 0.1)), #FFFFFF;
                        opacity: 1.0;
                        cursor: pointer;
/*
                        @include media-breakpoint-between(md, lg) {
                            border-width: 4px;
                        }
*/
//                        @include media-breakpoint-up(lg) {
                            border-width: 6px;
//                        }

                    }

                    .profileHeader__top__info__image-wrapper__upload-icon {
                        width: 22px;
                        height: 14px;
                        opacity: 1.0;
                        cursor: pointer;
/*
                        @include media-breakpoint-between(md, lg) {
                            width: 42px;
                            height: 27px;
                        }
*/
//                        @include media-breakpoint-up(lg) {
                            width: 56px;
                            height: 36px;
//                        }

                    }
                }

                &.active {
                    .profileHeader__top__info__image-wrapper__upload-icon {
                        animation-play-state: running;
                    }
                }

                &__upload-icon {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    stroke-dasharray: 400;
                    stroke-dashoffset: 0;
                    opacity: 0;
                    transition: opacity 0.5s ease-in-out;
                    height: 0;
                    width: 0;
                    animation: dash 5s cubic-bezier(0.4, 0, 1, 1) infinite;
                    animation-play-state: paused;

                    @keyframes dash {
                        to {
                            stroke-dashoffset: 1000;
                        }
                    }
                }
            }

            &__user-image {
                cursor: pointer;
                object-fit: cover;
//               border: 2px solid #FFFFFF;
                border-radius: 100px;
                height: 54px;
                width: 54px;
/*
                @include media-breakpoint-up(md) {
                    height: 108px;
                    width: 108px;
                    border-width: 4px;
                }
*/
//                @include media-breakpoint-up(lg) {
                    height: 162px;
                    width: 162px;
                    border-width: 6px;
//                }
            }
        }
    }
}